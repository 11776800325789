<template>
  <template v-if="user">
    <h1>Hallo {{ user.username }}</h1>
    <p>Schön, dass Du vorbei schaust.</p>
    <section v-if="subscribedRooms.length">
      <h2>Meine Räume</h2>
      <div class="grid gap-4">
        <RoomAsListItem
          v-for="room in subscribedRooms"
          :key="room.id"
          class="cursor-pointer"
          :model-value="room"
          @click="router.push({ name: 'room/items', params: { id: room.id } })"
        />
      </div>
    </section>
    <section v-else>
      <h2>Noch keine Räume eingerichtet</h2>
      <p>Wir melden uns, sobald wir Räume eingerichtet haben.</p>
    </section>
  </template>
  <template v-else>
    <h1>Hallo und herzlich willkommen!</h1>
    <p>
      Diese Seite wird aktuell überarbeitet und getestet. Vielen Dank, wenn Sie
      Interesse haben und sich beteiligen!
    </p>
    <div class="grid grid-cols-[1fr_2fr] gap-2">
      <p>Neu hier?</p>
      <NuxtLink to="/register" class="btn btn_secondary"
        >Zur Anmeldung</NuxtLink
      >
      <p>Schon Angemeldet?</p>
      <NuxtLink to="/login" class="btn btn_primary">Einloggen</NuxtLink>
    </div>
    <hr class="border-gray-700 my-4" />

    <section id="Rechtliches" class="text-sm text-gray-700">
      <p>
        <strong>Kontakt:</strong> Wende Dich an
        <a href="mailto:mail@a-friend.org">mail@a-friend.org</a>, wenn Du
        mittesten möchtest oder Fragen hast.
        <NuxtLink to="/imprint">Zum Impressum...</NuxtLink>
      </p>
      <p>
        <strong>Datenschutz: </strong>
        Bis zur Registrierung zeichnet diese Seite keine Daten von Ihnen auf.
        Verantwortlich für den Datenschutz ist die Betreiberin dieser Seite, die
        Firma
        <a href="https://www.nullachtvierzehn.de" target="_blank"
          >Nullachtvierzehn UG (haftungsbeschränkt)</a
        >. <NuxtLink to="/privacy">Zum Datenschutzhinweis...</NuxtLink>
      </p>
    </section>
  </template>
</template>

<script setup lang="ts">
import { useFetchRoomsQuery } from '~/graphql'

definePageMeta({
  layout: 'page',
})

const router = useRouter()
const user = await useCurrentUser()

const { data: dataOfSubscribedRooms } = await useFetchRoomsQuery({
  variables: {
    orderBy: ['LATEST_ACTIVITY_AT_DESC'],
  },
  requestPolicy: 'cache-and-network',
})

const subscribedRooms = computed(
  () => dataOfSubscribedRooms.value?.rooms?.nodes ?? []
)
</script>
